import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './PartnerButton.module.scss'
import {Link} from "react-router-dom";
import PartnerSvg from '@/assets/svg/subheader/partner.svg'

interface PartnerButtonProps {
    className?: string,
}
export const PartnerButton = (props: PartnerButtonProps) => {
    const { className } = props;
    return (
        <Link to={'https://leetpartner.skin'} className={classNames(cls.PartnerButton, {}, [className])}>
            <PartnerSvg/>
            <div>
                Партнерство
            </div>
        </Link>
    );
};
